
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CurrencyExchangeEntity, EMPTY_CURRENCY_EXCHANGE_ENTITY } from "domain/entity/CurrencyExchange/CurrencyExchangeEntity";
import { CurrencyExchangeRepository } from "domain/repository/CurrencyExchange/CurrencyExchangeRepo";
import _ from "lodash";
import { createCurrencyExchangeMaintenanceValidationSchema } from "presentation/constant/CurrencyExchange/CurrencyExchangeMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { CurrencyExchangeMaintenanceModel } from "presentation/model/CurrencyExchange/CurrencyExchangeMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface CurrencyExchangeMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CurrencyExchangeMaintenanceModel>> | ((value: SetStateAction<CurrencyExchangeMaintenanceModel>) => void),
    ],
    repo: CurrencyExchangeRepository,
}

export const CurrencyExchangeMaintenanceVM = ({dispatch,repo}:CurrencyExchangeMaintenanceVMProps) => {
    const [currencyExchangeMainDispatch] = dispatch;
        
    const loadDropdownOption = async () => {
	
    }

    const onSearch = async () => {
        const entities = await repo.getEntities();

        currencyExchangeMainDispatch(prevState =>({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_CURRENCY_EXCHANGE_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (allRows:CurrencyExchangeEntity[], selecedRows:CurrencyExchangeEntity[]) => {
        currencyExchangeMainDispatch(prevState => {
             
            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        currencyExchangeMainDispatch(prevState => {
             
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_CURRENCY_EXCHANGE_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: CurrencyExchangeEntity)=>{
        currencyExchangeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = ()=>{
        currencyExchangeMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_CURRENCY_EXCHANGE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        currencyExchangeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_CURRENCY_EXCHANGE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_CURRENCY_EXCHANGE_ENTITY,
                    allFormState:{}
                },
                isBackMaster: true,
            }
        })
    }

    const onSaveClicked = () => {
        currencyExchangeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: CurrencyExchangeEntity,isAdd: boolean) => {
        const valResult = await Validation(createCurrencyExchangeMaintenanceValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: {[x: string]: string} = {};  
        if (valResult) {
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' }; 

            currencyExchangeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }                        
                }
            });

            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        if(isAdd){
            return await repo.createEntity(currentEntity);
        }else{
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        currencyExchangeMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity : {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if(fieldKey === 'currencyExchange'){
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        currencyExchangeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        currencyExchangeMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }


    return {
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
    }
}