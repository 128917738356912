import { CurrencyExchangeMaintenanceConstant } from "./CurrencyExchangeMaintenanceConstant";

const SCREEN_CONSTANT = CurrencyExchangeMaintenanceConstant;
export const INITIAL_CURRENCY_EXCHANGE_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.FROM_CRURRENCY,
            field: "fromCurrency",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            //pinned: "left",
            //checkboxSelection: true,
            //headerCheckboxSelection: true,
            
        },
        {
            headerName: SCREEN_CONSTANT.TO_CRURRENCY,
            field: "toCurrency",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: SCREEN_CONSTANT.EFF_DATE,
            field: "effectiveDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            dataType: "date"
        },
        {
            headerName: SCREEN_CONSTANT.EXCHANGE_RATE,
            field: "exchangeRate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: SCREEN_CONSTANT.BANK_NO,
            field: "bankNo",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
        },
]
