import { CurrencyExchangeMaintenanceConstant } from "presentation/constant/CurrencyExchange/CurrencyExchangeMaintenanceConstant";
import { useCurrencyExchangeMaintenanceVM } from "presentation/hook/CurrencyExchange/useCurrencyExchangeMaintenanceVM";
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo } from "react";
import { FieldType, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CurrencyExchangeEditPanelTitleBar from "./CurrencyExchangeEditPanelTitleBar";

export const CurrencyExchangeMaintenanceFormPanel = () => {
    const [currencyExchangeState] = useCurrencyExchangeMaintenanceTracked();
    const currencyExchangeVM = useCurrencyExchangeMaintenanceVM();
    const {currentSelectedRow, masterState} = currencyExchangeState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = CurrencyExchangeMaintenanceConstant;
    

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const onNumberFieldChange = useCallback((e:any, fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        currencyExchangeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
    }, [currencyExchangeVM]);

    const memoFromCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.fromCurrency || ''}
                fieldValue={currentEntity?.fromCurrency}
                fieldLabel={SCREEN_CONSTANT.FROM_CRURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'fromCurrency'}
                isShowMissingError={true}
                maxLength={5}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    currencyExchangeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isRead, currentEntity?.fromCurrency, SCREEN_CONSTANT.FROM_CRURRENCY, isSaveClicked, currencyExchangeVM])

        const memoToCurrency = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.toCurrency || ''}
                    fieldValue={currentEntity?.toCurrency}
                    fieldLabel={SCREEN_CONSTANT.TO_CRURRENCY}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'toCurrency'}
                    isShowMissingError={true}
                    maxLength={5}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        currencyExchangeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.toCurrency, SCREEN_CONSTANT.TO_CRURRENCY, isSaveClicked, currencyExchangeVM])
        
        const memoExchangeRate = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
    
                <NumberInputComponent
                    label={SCREEN_CONSTANT.EXCHANGE_RATE}
                    maxLength={10}
                    fieldName={"exchangeRate"}
                    value={currentEntity?.exchangeRate??''}
                    errorMessage={allFormState?.exchangeRate??''}
                    disabled={!(isAdd||isEditable)}
                    decimalPlaces={2}
                    onChange={(e: any, fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
                />
                {/* <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.exchangeRate?.toString() || ''}
                    fieldValue={currentEntity?.exchangeRate?.toString() || ''}
                    fieldLabel={SCREEN_CONSTANT.EXCHANGE_RATE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    type="number"
                    fieldKey={'exchangeRate'}
                    isShowMissingError={true}
                    maxLength={10}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        currencyExchangeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         */}
            </div>
            , [SCREEN_CONSTANT.EXCHANGE_RATE, allFormState?.exchangeRate, currentEntity?.exchangeRate, isAdd, isEditable, onNumberFieldChange])

        const memoEffeDate = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
                <DatePickerComponent
                    disabled={isRead}
                    label={SCREEN_CONSTANT.EFF_DATE}
                    width="220px"
                    date={currentEntity?.effectiveDate}
                    fieldName="effectiveDate"
                    optional={false}
                    errorMessage={allFormState ? allFormState["effectiveDate"] : ""}
                    onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>
                        currencyExchangeVM.onFieldChange(fieldKey, fieldValue)} />
            </div>
            , [isRead, SCREEN_CONSTANT.EFF_DATE, currentEntity?.effectiveDate, allFormState, currencyExchangeVM])
        
            const memoBankNo = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "490px", marginBottom: "24px" }}>
        
                    <NbisCommonField
                        errorMessages={allFormState}
                        isReadOnly={isRead}
                        isShowOptional={true}
                        readOnlyValue={currentEntity?.bankNo || ''}
                        fieldValue={currentEntity?.bankNo}
                        fieldLabel={SCREEN_CONSTANT.BANK_NO}
                        isSaveClicked={isSaveClicked}
                        fieldType={FieldType.TEXT}
                        fieldKey={'bankNo'}
                        isShowMissingError={true}
                        maxLength={20}
                        requiredFieldList={[]}
                        onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                            currencyExchangeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
                </div>
                , [allFormState, isRead, currentEntity?.bankNo, SCREEN_CONSTANT.BANK_NO, isSaveClicked, currencyExchangeVM])
    
    const memoEditPanelTitleBar = useMemo(() => {
        return <CurrencyExchangeEditPanelTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{!isAdd ? currentEntity.fromCurrency : "NEW CODE"}</SidebarTitle>

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoFromCurrency} {memoToCurrency}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoEffeDate}{memoExchangeRate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoBankNo}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
