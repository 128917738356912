
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CurrencyExchangeMaintenance from "./CurrencyExchangeMaintenance";
import { CurrencyExchangeMaintenanceFormPanel } from "./CurrencyExchangeMaintenanceFormPanel";

export const CurrencyExchangeMasterView: React.FC = () => {
    const [currencyExchangeState] = useCurrencyExchangeMaintenanceTracked();
    const { isAdd, isEditable } = currencyExchangeState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <CurrencyExchangeMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<CurrencyExchangeMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}